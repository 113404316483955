<template lang="pug">
div.join
  div.view_container
    Header
    div.loading(v-show="loading")
      img(v-bind:src="loadingSrc")
      span Record my video
    div.add_participant_container(v-show="showJoin && !loading" v-on:click="showJoin=false")
      img(:src="recordIconSrc")
      div.title Add your own recording
      div.subtitle Just let us know who you are
      div.input_container
        input(v-model="name" type="text" placeholder="Enter your name" v-on:click="preventPropagation")
      div.input_container
        input(v-model="email" type="text" placeholder="Enter your email" v-on:keyup="keyEnterToSubmit" v-on:click="preventPropagation")
      br
      button.cta(v-on:click="addParticipant") Next
  div.video_overlay(v-if="showJoin" v-on:click="showJoin=false")
  div.control_overlay
    button.cta.record_button(v-on:click="showJoin=true" v-show="!showJoin" style="touch-action: manipulation")
      img(:src="recordIconSrc")
      span Add my recording
  div.video_container
    PlayProject(:slug="slug" v-on:ended="showJoin=true" :showAsk="true" ref="play_project")
</template>

<script>
/* global gtag */
import { GET_PROJECT_BY_SLUG_QUERY } from '../graphql/queries'
import { INVITE_PEOPLE_FROM_SLUG_MUTATION } from '../graphql/mutations'
import Header from '../components/Header'
import PlayProject from './PlayProject'

import { isEmail } from '../lib/utils'
// import config from '../appConfig'

export default {
  name: 'PlayAndJoinProject',
  metaInfo() {
    return {
        title: `Make a video with ${this.projectUser.firstName}`,
        meta: [
            { property: 'og:title', content: `Make a video with ${this.projectUser.firstName}`},
            { property: 'og:description', content: `Make a video with ${this.projectUser.firstName} for ${this.projectRecipientName}`},
            {property: 'og:video', content: `${this.projectIntroVideoUrl}`},
            {name: 'robots', content: 'index,follow'}
        ]
    }
  },
  props: {
    slug: String,
  },
  components: {
    Header,
    PlayProject,
  },
  watch: {

  },
  computed: {
    recordIconSrc () {
      return process.env.BASE_URL + "video_record_icon_white.svg"
    },
    playIconSrc () {
      return process.env.BASE_URL + "play_icon_white.svg"
    },
    loadingSrc () {
      return process.env.BASE_URL + "loading.gif"
    },
    projectUser () {
      return this.projectWithSlug?.user ? this.projectWithSlug?.user : ""
    },
    projectRecipientName () {
      return this.projectWithSlug?.recipientName ? this.projectWithSlug?.recipientName : ""
    },
    projectIntroVideoUrl () {
      return this.projectWithSlug?.introVideoUrl ? this.projectWithSlug?.introVideoUrl : null
    },
  },
  data () {
    return {
      loading: false,
      name: null,
      email: null,
      projectWithSlug: null,
      participant: null,
      showJoin: false,
      videoMuted: false,
      showPlayButton: false,
    }
  },
  apollo: {
    projectWithSlug () {
      return {
        // gql query
        query: GET_PROJECT_BY_SLUG_QUERY,
        // Static parameters
        variables () {
          return {
            slug: this.slug
          }
        },
        skip () {
          return !this.slug
        },
        fetchPolicy: 'cache-and-network',
      }
    }
  },
  methods: {
    preventPropagation: function(event) {
      if (event) event.stopPropagation()
    },
    addParticipant: async function(event) {
      if (event) event.stopPropagation()

      if (!isEmail(this.email)) {
        this.$toast.error('Enter a valid email')
        return
      }

      this.loading = true
      this.$apollo.mutate({
        mutation: INVITE_PEOPLE_FROM_SLUG_MUTATION,
        variables: {
          projectSlug: this.slug,
          participants: [{
            name: this.name,
            email: this.email
          }]
        },
      }).then((data) => {
        console.log(data)
        const participants = data.data.addParticipantsFromSlug.participants
        const errors = data.data.addParticipantsFromSlug.errors
        this.errorsToast(errors)

        if (participants) {
          this.participant = participants[0]
          this.next()
        }
        this.loading = false

      }).catch((error) => {
        console.error(error)
        this.errorToast(error)
        this.loading = false
      })
    },
    showJoinUI: function () {
      this.showJoin = true
    },
    toggleMute: function() {
      var video = document.getElementById("intro_video")
      video.muted = !video.muted
      this.videoMuted = video.muted
    },
    openFullscreen: function(elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen()
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen()
      }
    },
    next: function() {
      this.$router.push(
        {name:"AddParticipantVideoContainer",
        params: {
          participant: this.participant,
          slug: this.participant.slug
        }
      })
    },
    keyEnterToSubmit: function (e) {
      if (e.keyCode === 13) {
        this.addParticipant()
      }
    },
  },
  beforeUnmount: function () {
    this.$refs.play_project.stopProgressUpdate()
  },
  beforeRouteUpdate (to, from, next) {
    this.$refs.play_project.stopProgressUpdate()
    // react to route changes...
    // don't forget to call next()
    next()
  },
  mounted: function() {
    if (this.$route.params.slug) {
      this.slug = this.$route.params.slug
    }
    // window.vue = this

    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/play_and_join'})
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.join {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: #000000;
}

.add_participant_container {
  padding: 12% 0;
  height: 76%;
  position: relative;
  z-index: 8000;
}

.title {
  font-family: gibsonsemibold;
  font-size: 32px;
  color: #FFFFFF;
  text-align: left;
  margin-left: 8px;
}

.add_participant_container img {
  display: block;
  margin: 0px 14px 12px 8px;
  width: 40px;
  height: 40px;
}

.subtitle {
  font-family: gibsonregular;
  font-size: 24px;
  color: rgba(255,255,255,0.7);
  text-align: left;
  margin-left: 8px;
}

.control_overlay {
  position: absolute;
  bottom: 10%;
  left: 0px;
  width: 100%;
  text-align: center;
  z-index: 5000;
}

button.record_button {
  z-index: 5000;
}
button.record_button img {
  display: inline-block;
  margin: 0px 14px 2px -14px;
}

.video_container {
  z-index: 10;
  position:fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.video_overlay {
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.9;
  background-color:#000000;
}

.input_container {
  margin-top: 32px;
  background-color: rgba(255,255,255,0.27);
}

.input_container input {
  background-color: rgba(255,255,255,0);
  font-family: gibsonregular;
  font-size: 30px;
  color: #ffffff !important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(233, 225, 225, 0.7);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgba(255,255,255,0.7);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: rgba(255,255,255,0.7);
}


</style>
